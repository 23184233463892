import { captureConsoleIntegration } from "@sentry/integrations"
import {
  BrowserProfilingIntegration,
  // replayIntegration,
  init as SentryInit,
  // browserTracingIntegration,
  // reactRouterV5BrowserTracingIntegration,
} from "@sentry/react"
// Note: browserTracingIntegration is still beta, Let's keep it disable

import { denyUrls, ignoreErrors } from "helpers/sentry"

export const initialLazySizes = () => {
  // Loading laze-loaded assets shouldn't be a blocker for the parent events
  // Like: DOMContentLoaded on load
  setTimeout(() => {
    if (window.location.href.includes("stop_lazy=true")) {
      return
    }
    if (!window.lazySizes.cfg.init) {
      window.lazySizes.init()
      window.lazySizes.cfg.init = true
    }
  }, 50)
}

export const afterLazySizesLoaded = (event) => {
  const afterHook = event.target.getAttribute("data-after-hook")
  if (afterHook) {
    switch (afterHook) {
      case "eao":
        // Run an EAO init
        // eslint-disable-next-line no-undef
        request_badge()
        break
      case "renderOptIn":
        window.renderOptIn()
        break
    }
  }
}

export const sentryInitializer = () => {
  if (["production", "staging", "dev"].includes(sl.config.env)) {
    SentryInit({
      denyUrls,
      dsn: sl.config.sentry_dsn,
      environment: sl.config.env,
      ignoreErrors,
      integrations: [
        BrowserProfilingIntegration(),
        // browserTracingIntegration({
        //   routingInstrumentation: reactRouterV5BrowserTracingIntegration({
        //     history: window.appHistory,
        //   }),
        // }),
        // replayIntegration(),
        captureConsoleIntegration({
          levels: ["error"],
        }),
      ],
      profilesSampleRate: sl.config.env === "production" ? 0.5 : 1.0,
      // Session Replay
      replaysOnErrorSampleRate: sl.config.env === "production" ? 0.2 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      replaysSessionSampleRate: sl.config.env === "production" ? 0.01 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // Performance Monitoring
      // tracePropagationTargets: ["localhost", /^(?:\/(uk|au|ca))?\/api/], // For distributed tracing
      // tracesSampleRate: sl.config.env === "production" ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    })
  }
}
