import * as Sentry from "@sentry/react"
import { Route as ReactRoute } from "react-router-dom"

// export const Route = ReactRoute
export const Route = Sentry.withSentryRouting(ReactRoute)

export const denyUrls = [
  /www\.googletagmanager\.com\/gtm\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  /^safari-extension::\/\//i,
  /^pptr:\/\//i, //pptr://__puppeteer_evaluation_script__
  /staticw2\.yotpo\.com/i,
  /js\.stripe\.com/i,
  /beaconapi\.helpscout\.net/i,
  /chatapi\.helpscout\.net/i,
  /cdn\.attn\.tv/i,
  /imgs\.signifyd\.com/i,
]

export const ignoreErrors = [
  // Ignore network/ fetch api errors
  // https://github.com/getsentry/sentry-javascript/issues/6376#issuecomment-1455673228
  /Network Error/i,
  /Failed to fetch/i,
  /Fetch Error/i,
  /Loading chunk/i,
  // Safari webkit
  /.*@webkit-masked-url.*/,
  // https://github.com/getsentry/sentry-javascript/issues/3040
  /.*window.webkit.messageHandlers.*/,
  /DIGIOH*/,
  /<unlabeled event>/i,
]
