import Cookies from "js-cookie"

// Inject fetch polyfill if fetch is unsuported
if (typeof window !== "undefined" && !window.fetch) {
  const fetch = import("whatwg-fetch/fetch")
}

const api = {
  authGet(url) {
    return fetch(formatUrl(url), {
      credentials: "include",
      redirect: "error",
    })
      .then(statusHelper)
      .then((response) => response.json())
      .catch((error) => error)
      .then((data) => {
        return data
      })
  },
  authPost(url, body, contentType = undefined) {
    return postBare(url, body, contentType, Cookies.get("jwt") || "")
      .then(statusHelper)
      .then((response) => response.json())
      .then((data) => {
        return data
      })
  },
  base() {
    return (
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port != "" ? `:${window.location.port}` : "") +
      "/"
    )
  },
  delete(url, body, contentType = undefined) {
    return deleteBare(url, body, contentType)
      .then(statusHelper)
      .then((response) => response.json())
      .then((data) => {
        return data
      })
  },
  get(url) {
    return fetch(formatUrl(url), { credentials: "include", redirect: "error" })
      .then(statusHelper)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error)
      })
      .then((data) => {
        return data
      })
  },
  getHtml(url) {
    return fetch(formatUrl(url), { credentials: "include", redirect: "error" })
      .then(statusHelper)
      .then((response) => response.text())
      .catch((error) => {
        console.error(error)
      })
      .then((data) => {
        return data
      })
  },
  getNoCache(url) {
    return fetch(formatUrl(url), { cache: "no-store", credentials: "include" })
      .then(statusHelper)
      .then((response) => response.json())
      .catch((error) => error)
      .then((data) => {
        return data
      })
  },
  getNoCredentials(url) {
    return fetch(formatUrl(url), { mode: "cors", redirect: "error" })
      .then(statusHelper)
      .then((response) => response.json())
      .catch((error) => error)
      .then((data) => {
        return data
      })
  },
  patch(url, body, contentType = undefined) {
    return patchBare(url, body, contentType)
      .then(statusHelper)
      .then((response) => response.json())
      .then((data) => {
        return data
      })
  },
  post(url, body, contentType = undefined) {
    return postBare(url, body, contentType)
      .then(statusHelper)
      .then((response) => response.json())
      .then((data) => {
        return data
      })
  },
  postNoParse(url, body, contentType = undefined) {
    return postBare(url, body, contentType).then(statusHelper)
  },
  postParsed(url, body) {
    body = JSON.stringify(body)
    return fetch(formatUrl(url), { body, method: "POST" })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((err) => {
        console.error("postParsed JSON Parse Failed", err)
      })
  },
  put(url, body, contentType = undefined) {
    return putBare(url, body, contentType)
      .then(statusHelper)
      .then((response) => response.json())
      .then((data) => {
        return data
      })
  },
}

function postBare(url, body, contentType = undefined) {
  if (typeof contentType == "undefined") {
    if (typeof body == "object") {
      contentType = "application/json; charset=utf-8"
      body = JSON.stringify(body)
    } else {
      contentType = "application/x-www-form-urlencoded; charset=UTF-8"
    }
  }

  var headers = {}
  const csrfTag = document.querySelector("meta[name='csrf-token']")
  if (csrfTag) {
    headers = {
      "X-CSRF-Token": csrfTag.getAttribute("content"),
    }
  }

  if (contentType) {
    headers["Content-Type"] = contentType
  }

  return fetch(formatUrl(url), {
    body,
    cache: "no-store",
    credentials: "include",
    headers,
    method: "POST",
  })
}

function putBare(url, body, contentType = undefined) {
  if (typeof contentType == "undefined") {
    if (typeof body == "object") {
      contentType = "application/json; charset=utf-8"
      body = JSON.stringify(body)
    } else {
      contentType = "application/x-www-form-urlencoded; charset=UTF-8"
    }
  }

  const csrfTag = document.querySelector("meta[name='csrf-token']")

  var headers = {}
  if (csrfTag) {
    headers = {
      "X-CSRF-Token": csrfTag.getAttribute("content"),
    }
  }
  if (contentType) {
    headers["Content-Type"] = contentType
  }

  return fetch(formatUrl(url), {
    body,
    cache: "no-store",
    credentials: "include",
    headers,
    method: "PUT",
  })
}
function patchBare(url, body, contentType = undefined) {
  if (typeof contentType == "undefined") {
    if (typeof body == "object") {
      contentType = "application/json; charset=utf-8"
      body = JSON.stringify(body)
    } else {
      contentType = "application/x-www-form-urlencoded; charset=UTF-8"
    }
  }

  const csrfTag = document.querySelector("meta[name='csrf-token']")

  if (csrfTag) {
    var headers = {
      "X-CSRF-Token": csrfTag.getAttribute("content"),
    }
  } else {
    headers = {}
  }
  if (contentType) {
    headers["Content-Type"] = contentType
  }

  return fetch(formatUrl(url), {
    body,
    cache: "no-store",
    credentials: "include",
    headers,
    method: "PATCH",
  })
}

function deleteBare(url, body, contentType = undefined) {
  if (typeof contentType == "undefined") {
    if (typeof body == "object") {
      contentType = "application/json; charset=utf-8"
      body = JSON.stringify(body)
    } else {
      contentType = "application/x-www-form-urlencoded; charset=UTF-8"
    }
  }

  const csrfTag = document.querySelector("meta[name='csrf-token']")

  if (csrfTag) {
    var headers = {
      "X-CSRF-Token": csrfTag.getAttribute("content"),
    }
  } else {
    headers = {}
  }
  if (contentType) {
    headers["Content-Type"] = contentType
  }

  return fetch(formatUrl(url), {
    body,
    cache: "no-store",
    credentials: "include",
    headers,
    method: "DELETE",
  })
}
async function statusHelper(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  } else {
    const body = await response.json()
    if (body.errors || body.error) {
      return Promise.reject(body)
    }
    return Promise.reject(new Error(response.statusText))
  }
}

const formatUrl = (url) => {
  // Add basename for relative path, unless it is a jewlrorders url
  if (url instanceof URL) {
    url.pathname = formatUrl(url.pathname)
    return url
  }

  if (
    url?.startsWith("http") ||
    url?.startsWith(sl.config.secure_url) ||
    url?.startsWith("/secure/api")
  ) {
    return url
  }

  return `${sl.basename}${url}`
}

export default api
