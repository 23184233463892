import Cookies from "js-cookie"

import api from "apis"
import { isTestMode } from "helpers/application"

export const hitFallback = (fn) => {
  if (window.hitFallbackTimeout) {
    console.log("clearing existing fallback")
    clearTimeout(window.hitFallbackTimeout)
  }
  console.log("set new hitFallback")
  window.hitFallbackTimeout = setTimeout(function () {
    console.log("hitCallback never finished, hitFallback was called")
    fn()
  }, 2000)
  return function () {
    if (window.hitFallbackTimeout) {
      clearTimeout(window.hitFallbackTimeout)
    }
    console.log("hitCallback was successful")
    fn()
  }
}

export const jewlr_link_decorator = (url) => {
  var qs_params = ""
  try {
    if (
      !(typeof ga === "undefined") &&
      Object.prototype.hasOwnProperty.call(ga, "loaded") &&
      ga.loaded === true
    ) {
      var trackers = ga.getAll()
      if (trackers.length > 0) {
        for (let i = 0, len = trackers.length; i < len; i += 1) {
          if (trackers[i].get("trackingId") === sl.config.google_analytics_id) {
            var ga_linker_params = trackers[i].get("linkerParam")
            qs_params += "&" + ga_linker_params
            break
          }
        }
      }
    }
  } catch (e) {
    console.log("GA DECORATE FAIL", e)
  }

  try {
    if (Cookies.get("ref")) {
      qs_params += `&ref=${Cookies.get("ref")}`
    }
  } catch (e) {
    // continue silently
  }

  try {
    if (Cookies.get("fs_uid")) {
      qs_params += "&fs_uid=" + encodeURIComponent(Cookies.get("fs_uid"))
    }
  } catch (e) {
    // continue silently
    console.log("Unable to fetch fs_uid: ", e)
  }

  if (url.indexOf("?") > -1) url += qs_params
  else url += "?" + qs_params

  return url
}

export function submit_social_tracking(network, socialAction, optTarget) {
  if (!isTestMode) {
    dataLayer.push({
      event: "jewlr-social",
      socialAction: socialAction,
      socialNetwork: network,
      socialTarget: optTarget,
    })
  }
}

export function submit_ga_event_click(
  click_category,
  click_type,
  opt_click_label,
  opt_click_value,
  opt_other_params_object,
  nonInteraction
) {
  if (!isTestMode) {
    dataLayer.push({
      clickCategory: click_category,
      clickLabel: opt_click_label,
      clickType: click_type,
      event: "jewlr-click",
      nonInteraction: nonInteraction ? true : click_category == "Page",
    })
  }
}

export const submit_ga_tracker = (tracking_path) => {
  if (!isTestMode) {
    dataLayer.push({
      customPagePath: tracking_path.replace(/^.*\/\/[^/]+/, ""),
      event: "jewlr-page-view",
    })
  }
}

export const setUTMCampaign = () => {
  // Set utm_campaign into cookie if set in URL
  let uri = new window.URI(window.location.href)
  if (uri.hasQuery("utm_campaign")) {
    var utm_campaign = uri.search(true).utm_campaign
    Cookies.set("utm_campaign", utm_campaign, { expires: 365, secure: true })
  }
}

export const submit_ga_user_id = (user_id) => {
  if (!isTestMode) {
    dataLayer.push({
      userId: user_id,
    })
  }
}

export const submitUserEvent = (params = {}) => {
  let url = "/api/v4/user/event"
  api.post(url, params)
}

export async function shorten_url(url_to_shorten) {
  var result_url = url_to_shorten
  try {
    result_url = await api.get(
      "/api/v10/utilities/shorten?url=" + encodeURIComponent(url_to_shorten)
    )
  } catch (ex) {
    console.error(ex)
  }
  return result_url.data.shortened_url
}

export function generateSourceURL(url, source_type) {
  try {
    if (source_type !== undefined) {
      if (url.indexOf("?") === -1) {
        url += "?"
      }
      url += "&utm_source=jewlrweb"
      url += "&utm_medium=" + encodeURIComponent(source_type)
      url += "&utm_content=product_share"
      // utm_campaign = Cookies.get("utm_campaign")
      // if (utm_campaign !== null) {
      //   url += "&utm_campaign=" + utm_campaign
      // }
    }
    return url
  } catch (e) {
    console.log(e)
  }
}

export function get_redirect_url(url) {
  try {
    return "/redirector?u=" + encodeURIComponent(url)
  } catch (e) {
    console.log(e)
  }
}
