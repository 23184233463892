/* eslint-disable sort-keys */
const translations = {
  Cart: "Cart",
  Color: "Colour",
  Customize: "Customise",
  Dollar: "Dollar",
  Gray: "Grey",
  Honor: "Honour",
  Instalment: "Instalment",
  JEWELRY: "JEWELLERY",
  Jewelry: "Jewellery",
  Labor: "Labour",
  Mom: "Mum",
  Moms: "Mums",
  Personalization: "Personalisation",
  Personalize: "Personalise",
  Personalized: "Personalised",
  Personalized_Jewelry: "Personalised Jewellery",
  Personalizing: "Personalising",
  Shipping: "Shipping",
  Symbolize: "Symbolise",
  Vermeil: "Vermeil",
  WatchBandColor: "Watch Band Colour",
  WatchCaseColor: "Watch Case Colour",
  authorize: "authorise",
  business: "working",
  cart: "cart",
  center: "centre",
  collections: {
    Jewelry: "Jewellery",
    Personalized: "Personalised",
    Personalized_Jewelry: "Personalised Jewellery",
    Personalized_jewelry: "Personalised jewellery",
    color: "colour",
    jewelry: "jewellery",
    mom: "mum",
    personalized: "personalised",
  },
  color: "colour",
  customization: "customisation",
  customize: "customise",
  customizable: "customisable",
  dollar: "dollar",
  favorite: "favourite",
  Favorite: "Favourite",
  gray: "grey",
  honor: "honour",
  instalment: "instalment",
  itemize: "itemise",
  Itemize: "Itemise",
  itemized: "itemised",
  Itemized: "Itemised",
  jeweler: "jeweller",
  jewelry: "jewellery",
  labor: "labour",
  mom: "mum",
  moms: "mums",
  neighbor: "neighbour",
  neighboring: "neighbouring",
  personalizable: "personalisable",
  personalization: "personalisation",
  personalize: "personalise",
  personalized: "personalised",
  personalized_jewelry: "personalised jewellery",
  personalizing: "personalising",
  recognized: "recognised",
  ship: "ship",
  shipping: "shipping",
  ships: "dispatches",
  specialize: "specialise",
  symbolize: "symbolise",
  tenK: "10K",
  tenk: "10k",
  "number.currency.code": "AUD",
  "number.currency.format.delimiter": ",",
  "number.currency.format.format": "%u%n",
  "number.currency.format.precision": 2,
  "number.currency.format.separator": ".",
  "number.currency.format.significant": false,
  "number.currency.format.strip_insignificant_zeros": false,
  "number.currency.format.unit": "$",
  "number.format.delimiter": ",",
  "number.format.precision": 3,
  "number.format.round_mode": "default",
  "number.format.separator": ".",
  "number.format.significant": false,
  "number.format.strip_insignificant_zeros": false,
  "number.human.decimal_units.format": "%n %u",
  "number.human.decimal_units.units.billion": "Billion",
  "number.human.decimal_units.units.million": "Million",
  "number.human.decimal_units.units.quadrillion": "Quadrillion",
  "number.human.decimal_units.units.thousand": "Thousand",
  "number.human.decimal_units.units.trillion": "Trillion",
  "number.human.decimal_units.units.unit": "",
  "number.human.format.delimiter": "",
  "number.human.format.precision": 3,
  "number.human.format.significant": true,
  "number.human.format.strip_insignificant_zeros": true,
  "number.human.storage_units.format": "%n %u",
  "number.human.storage_units.units.byte.one": "Byte",
  "number.human.storage_units.units.byte.other": "Bytes",
  "number.human.storage_units.units.eb": "EB",
  "number.human.storage_units.units.gb": "GB",
  "number.human.storage_units.units.kb": "KB",
  "number.human.storage_units.units.mb": "MB",
  "number.human.storage_units.units.pb": "PB",
  "number.human.storage_units.units.tb": "TB",
  "number.nth.ordinalized": {},
  "number.nth.ordinals": {},
  "number.percentage.format.delimiter": "",
  "number.percentage.format.format": "%n%",
  "number.precision.format.delimiter": "",
  "address.city": "City",
  "address.country": "Australia",
  "address.state": "State",
  "address.zip": "Postcode",
}
export default translations
