import Cookies from "js-cookie"

export const storePref = (pref, value, style_code = null) => {
  // We don't care about style_code as a pref, so no need to
  //   pollute the cookies with this data
  if (pref == "style_code") {
    return
  }
  try {
    if (style_code && window.localStorage) {
      let productPrefs = getProductPrefs(style_code)
      productPrefs[pref] = value
      localStorage.setItem("prefs:" + style_code, JSON.stringify(productPrefs))
    }
    let prefObj = Cookies.getJSON("userPrefs") || {}
    try {
      const values = Object.values(prefObj).join("")
      const test = encodeURIComponent(values) // eslint-disable-line no-unused-vars
    } catch (e) {
      // Clear userPrefs cookie if there's an invalid value in it
      Cookies.remove("userPrefs")
      return storePref(pref, value, style_code)
    }
    prefObj[pref] = value
    Cookies.set("userPrefs", prefObj, { secure: true })
  } catch (e) {
    console.error("STORE PREF ERROR", e)
  }
}

export const getPref = (pref, style_code = null) => {
  let value
  if (style_code && window.localStorage) {
    let productPrefs = getProductPrefs(style_code)
    if (productPrefs[pref]) {
      value = productPrefs[pref]
    }
  }
  let prefObj = Cookies.getJSON("userPrefs") || {}
  value = prefObj[pref]
  try {
    const test = encodeURIComponent(value) // eslint-disable-line no-unused-vars
  } catch (e) {
    // unparseable value, so we kill it
    storePref(pref, "", style_code)
    value = undefined
  }
  return value
}

export const getProductPrefs = (style_code) => {
  if (window.localStorage) {
    const prefString = localStorage.getItem("prefs:" + style_code)
    let prefs = {}
    if (prefString) {
      prefs = JSON.parse(prefString)
    }
    try {
      const values = Object.values(prefs).join("")
      const test = encodeURIComponent(values) // eslint-disable-line no-unused-vars
    } catch (e) {
      // clear user prefs if there's an invalid value in it
      localStorage.removeItem(`prefs:${style_code}`)
      return {}
    }
    return prefs
  } else {
    return {}
  }
}

export const removePref = (pref) => {
  try {
    let prefObj = Cookies.getJSON("userPrefs")
    delete prefObj[pref]
    Cookies.set("userPrefs", prefObj, { secure: true })
  } catch (e) {
    return
  }
}
